import ApiUrl from "./api-url";
import axios, {isCancel} from 'axios';
import {ApiHeaderOptions} from "./api-header-options";
import { errorHandlingService } from "./error-handling-service";


const getMyTasks = async () => {
    const url = `${ApiUrl}/v1/projects/tasks/me/`;
    return await axios.get(url, await ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " get my tasks", "high");
    });
}

const getTask = async (id:any) => {
    const url = `${ApiUrl}/v1/projects/tasks/${id}/`;
    return await axios.get(url, await ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, "vget task", "high");
    });
}

const getTaskOptions = async (id:any) => {
    const url = `${ApiUrl}/v1/projects/tasks/${id}/`;
    return await axios.options(url, await ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " get task options", "high");
    });
}

const editTask = async (id:any, data:any) => {
    const url = `${ApiUrl}/v1/projects/tasks/${id}/`;
    return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
        errorHandlingService(error, " edit task", "high");
    });
}

export {getMyTasks, getTask, editTask, getTaskOptions};

