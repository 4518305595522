import { Route, Routes } from "react-router-dom";
import App from "./App";
import Home from "./pages/home/home";
import {
  ProtectedRoute,
  UnprotectedRoute,
} from "./components/context/ProtectedRoute";
import LoginPage from "./pages/account/login";
import ProjectDashboard from "./pages/project/project-dashboard";
import CreateProject from "./pages/project/create-project";
import ManageProject from "./pages/project/manage-project";
import Register from "./pages/account/register";
import ForgotPassword from "./pages/account/forgot-password";
import NotAuthorized from "./components/errors/not-authorized-error";
import NotFound from "./pages/error/not-found";
import TaskDashboard from "./pages/tasks/task-dashboard";
import TaskView from "./pages/tasks/task-view";
import TaskForm from "./pages/form/task-form";
import CreateCustomForm from "./pages/form/create-custom-form";
import SetNewPassword from "./pages/account/set-new-password";
import ActivateAccount from "./pages/account/activate";
import FleetDashboard from "./pages/fleet/fleet-dashboard";
import AddVehicle from "./pages/fleet/add-vehicle";
import ManageVehicle from "./pages/fleet/manage-vehicle";
import CreateStage from "./pages/project/project-stages/create-stage";
import CreateProjectStatus from "./pages/project/project-status/create-status";
import CreateProjectType from "./pages/project/project-type/create-project-type";
import { Health } from "./pages/health";
import SiteDashboard from "./pages/site/site-dashboard";
import CreateSite from "./pages/site/create-site";
import ManageSite from "./pages/site/manage-site";
import Profile from "./pages/account/profile";
import CreateVehicleStatus from "./pages/fleet/create-status";
import CreateDepartment from "./pages/organization/department/create-department";
import ProjectSettings from "./pages/project/project-settings/project-settings";
import EditProjectStage from "./pages/project/project-stages/edit-project-stage";
import EditDepartment from "./pages/organization/department/edit-department";
import UsersManagement from "./pages/organization/users/management";
import EditUser from "./pages/organization/users/edit-user";
import FormDashboard from "./pages/form/form-dashboard";
import EditForm from "./pages/form/form-manage";
import EditProjectType from "./pages/project/project-type/edit-project-type";
import PermissionGroupDashboard from "./pages/permission-group/permission-group-dashboard";
import PermissionGroupEdit from "./pages/permission-group/permission-group-edit";
import PermissionGroupCreate from "./pages/permission-group/permission-group-create";
import SelectSupportRequest from "./pages/special-request/select-support-request";
import SupportOnboardingForm from "./pages/special-request/onboarding-form";
import OffboardingDashboard from "./pages/special-request/offboarding/offboarding-dashboard";
import OffboardingForm from "./pages/special-request/offboarding/offboarding-form";
import EmailBuilder from "./pages/system-notification/email-builder";
import SupportSettings from "./pages/request-dashboard/support-settings";
import EmailDashboard from "./pages/system-notification/email-dashboard";
import EmailManage from "./pages/system-notification/email-manage";
import SystemNotificationDashboard from "./pages/system-notification/system-notification-dashboard";
import OrganizationDashboard from "./pages/organization/organization-dashboard";
import DepartmentDashboard from "./pages/organization/department/department-dashboard";
import SystemLogsDashboard from "./pages/system-logs/system-logs-dashboard";
import TicketForm from "./pages/my-requests/ticket-form";
import RequestDashboard from "./pages/request-dashboard/request-dashboard";
import RequestView from "./pages/request-dashboard/request-view";
import MyRequestDashboard from "./pages/my-requests/my-requests-dashboard";
import MyRequestView from "./pages/my-requests/request-view";
import AddTrainingCertificate from "./pages/special-request/add-training-cerificate";
import EditProjectStatus from "./pages/project/project-status/edit-project-status";
import WhatIsNewPage from "./pages/what-is-new";
import EmailsLogsDashboard from "./pages/system-logs/email-logs-dashboard";
import EmailLogView from "./pages/system-logs/email-log-view";
import CardsDashboards from "./pages/organization/cards/cards-dashboards";
import CardView from "./pages/organization/cards/card-view";
import OffboardingNotRegisterUser from "./pages/special-request/offboarding/offboarding-not-register-user";


const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<App />}>
          {/* Home Route */}
          <Route path="/" element={<ProtectedRoute element={Home} />} />
            
            {/* What's New Route */}
          <Route path="/whats-new" element={<WhatIsNewPage />} />

          {/* Project Routes */}
          <Route
            path="/projects"
            element={
              <ProtectedRoute
                element={ProjectDashboard}
                permission="view_projectsdashboard"
              />
            }
          />
          <Route
            path="/projects/create"
            element={
              <ProtectedRoute
                element={CreateProject}
                permission="add_projects"
              />
            }
          />
          <Route
            path="/projects/id/:id"
            element={
              <ProtectedRoute
                element={ManageProject}
                permission="view_projects"
              />
            }
          />
          <Route
            path="/projects/create-project-type"
            element={
              <ProtectedRoute
                element={CreateProjectType}
                permission="add_projecttypes"
              />
            }
          />
          <Route
            path="/projects/create-stage"
            element={
              <ProtectedRoute element={CreateStage} permission="add_stages" />
            }
          />
          <Route
            path="/projects/create-status"
            element={
              <ProtectedRoute
                element={CreateProjectStatus}
                permission="add_status"
              />
            }
          />
          <Route
            path="/projects/settings/status/id/:id"
            element={
              <ProtectedRoute
                element={EditProjectStatus}
                permission="view_status"
              />
            }
          />
          <Route
            path="/projects/settings"
            element={
              <ProtectedRoute
                element={ProjectSettings}
                permission="change_projects"
              />
            }
          />
          <Route
            path="/projects/settings/stage/id/:id"
            element={
              <ProtectedRoute
                element={EditProjectStage}
                permission="view_stages"
              />
            }
          />

          <Route
            path="/projects/settings/project-type/id/:id"
            element={
              <ProtectedRoute
                element={EditProjectType}
                permission="view_projecttypes"
              />
            }
          />

          {/* System Logs Route */}
          <Route
            path="/system-logs"
            element={<ProtectedRoute
              element={SystemLogsDashboard}
              permission="view_apilogsmodel"
            />}
          />

          {/* Email Logs Route */}
          <Route
            path="/email-logs"
            element={<ProtectedRoute
              element={EmailsLogsDashboard}
              permission="view_emaillog"
            />}
          />
          <Route
            path="/email-logs/id/:id"
            element={<ProtectedRoute
              element={EmailLogView}
              permission="view_emaillog"
            />}
          />

          {/* Organization Routes */}
          <Route
            path="/organization"
            element={
              <ProtectedRoute
                element={OrganizationDashboard}
                permission="view_organizationdashboard"
              />
            }
          />
          <Route
            path="/organization/create-department"
            element={
              <ProtectedRoute
                element={CreateDepartment}
                permission="add_departments"
              />
            }
          />
          <Route
            path="/organization/department/id/:id"
            element={
              <ProtectedRoute
                element={EditDepartment}
                permission="view_departments"
              />
            }
          />
          <Route
            path="/organization/department"
            element={<ProtectedRoute element={DepartmentDashboard} permission="view_departments" />}
          />

          {/* Users Management Routes */}
          <Route
            path="/organization/users"
            element={
              <ProtectedRoute
                element={UsersManagement}
                permission="view_usersdashboard"
              />
            }
          />
          <Route
            path="/organization/users/id/:id"
            element={
              <ProtectedRoute element={EditUser} permission="view_user" />
            }
          />

          {/* Cards Management Routes */}
          <Route
            path="/organization/cards"
            element={
              <ProtectedRoute
                element={CardsDashboards}
                permission="view_employeecard"
              />
            }
          />
          <Route
            path="/organization/cards/id/:id"
            element={
              <ProtectedRoute
                element={CardView}
                permission="view_employeecard"
              />
            }
          />

          {/* System Notification Route */}
          <Route
            path="/system-notification/emails/builder"
            element={<ProtectedRoute
              element={EmailBuilder}
              permission="add_emailtemplates"
            />}
          />

          <Route
            path="/system-notification/emails"
            element={<ProtectedRoute
              element={EmailDashboard}
              permission="view_emailtemplates"
            />}
          />

          <Route
            path="/system-notification/emails/id/:id"
            element={<ProtectedRoute
              element={EmailManage}
              permission="view_emailtemplates"
            />}
          />

          <Route
            path="/system-notification"
            element={
              <ProtectedRoute
                element={SystemNotificationDashboard}
                permission="view_systemnotificationsdashboard"
              />
            }
          />

          {/* Task Routes */}
          <Route
            path="/tasks"
            element={
              <ProtectedRoute
                element={TaskDashboard}
                permission="view_tasksdashboard"
              />
            }
          />
          <Route
            path="/tasks/id/:id"
            element={
              <ProtectedRoute element={TaskView} permission="view_tasks" />
            }
          />
          <Route
            path="/tasks/form/id/:id/"
            element={
              <ProtectedRoute element={TaskForm} permission="view_tasks" />
            }
          />

          {/* Fleet Routes */}
          <Route
            path="/fleet"
            element={
              <ProtectedRoute
                element={FleetDashboard}
                permission="view_vehiclesdashboard"
              />
            }
          />
          <Route
            path="/fleet/id/:id"
            element={
              <ProtectedRoute
                element={ManageVehicle}
                permission="view_vehicles"
              />
            }
          />
          <Route
            path="/fleet/add-vehicle"
            element={
              <ProtectedRoute element={AddVehicle} permission="add_vehicles" />
            }
          />
          <Route
            path="/fleet/create-status"
            element={
              <ProtectedRoute
                element={CreateVehicleStatus}
                permission="add_vehiclestatus"
              />
            }
          />

          {/* Auth Routes */}
          <Route
            path="/login"
            element={<UnprotectedRoute element={LoginPage} />}
          />
          <Route
            path="/register"
            element={<UnprotectedRoute element={Register} />}
          />
          <Route
            path="/set-new-password"
            element={<UnprotectedRoute element={SetNewPassword} />}
          />
          <Route
            path="/forgot-password"
            element={<UnprotectedRoute element={ForgotPassword} />}
          />
          <Route
            path="/activate"
            element={<UnprotectedRoute element={ActivateAccount} />}
          />

          {/* Site Routes */}
          <Route
            path="/sites"
            element={
              <ProtectedRoute
                element={SiteDashboard}
                permission="view_sitesdashboard"
              />
            }
          />
          <Route
            path="/sites/create"
            element={
              <ProtectedRoute element={CreateSite} permission="add_sites" />
            }
          />
          <Route
            path="/sites/id/:id"
            element={
              <ProtectedRoute element={ManageSite} permission="view_sites" />
            }
          />

          {/* Special Request Routes */}

          <Route
            path="/special-request"
            element={<ProtectedRoute element={SelectSupportRequest} permission="view_specialrequestdashboard" />}
          />
          <Route
            path="/special-request/onboarding"
            element={<ProtectedRoute element={SupportOnboardingForm} permission="view_onboarding" />}
          />
          <Route
            path="/special-request/offboarding"
            element={<ProtectedRoute element={OffboardingDashboard} permission="view_offboarding" />}
          />
          <Route
            path="/special-request/offboarding/id/:id"
            element={<ProtectedRoute element={OffboardingForm} permission="view_offboarding" />}
          />
          <Route
            path="/special-request/add-training-certificate"
            element={<ProtectedRoute element={AddTrainingCertificate} permission="view_trainingcertificate" />}
          />
          <Route
            path="/special-request/offboarding/not-in-list"
            element={<ProtectedRoute element={OffboardingNotRegisterUser} permission="view_offboarding" />}
          />
          {/* <Route
            path="/special-request/request-employee-card"
            element={<ProtectedRoute element={RequestEmployeeCard} />}
          /> */}

          {/* Request Dashboard Routes */}
          <Route
            path="/requests"
            element={<ProtectedRoute element={RequestDashboard} permission="view_ticketdashboard" />}
          />
          <Route
            path="/requests/id/:id"
            element={<ProtectedRoute element={RequestView} permission="view_ticketdashboard" />}
          />
          <Route
            path="/requests/settings"
            element={<ProtectedRoute element={SupportSettings} permission="view_supportsettings" />}
          />

          {/* My Requests Routes */}
          <Route
            path="/my-requests"
            element={<ProtectedRoute element={MyRequestDashboard} />}
          />
          <Route
            path="/my-requests/id/:id"
            element={<ProtectedRoute element={MyRequestView} />}
          />

          <Route
            path="/open-ticket"
            element={<ProtectedRoute element={TicketForm} />}
          />

          {/* Auth Routes
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/set-new-password" element={<SetNewPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/activate" element={<ActivateAccount />} /> */}

          {/* Permission Group Routes */}
          <Route
            path="/permission-group"
            element={
              <ProtectedRoute
                element={PermissionGroupDashboard}
                permission="view_permissionsdashboard"
              />
            }
          />
          <Route
            path="/permission-group/id/:id"
            element={
              <ProtectedRoute
                element={PermissionGroupEdit}
                permission="view_permission"
              />
            }
          />
          <Route
            path="/permission-group/create"
            element={
              <ProtectedRoute
                element={PermissionGroupCreate}
                permission="add_permission"
              />
            }
          />

          {/* Profile Route */}
          <Route
            path="/profile"
            element={<ProtectedRoute element={Profile} />}
          />

          {/* Form Routes */}
          <Route
            path="/forms/create"
            element={
              <ProtectedRoute
                element={CreateCustomForm}
                permission="add_forms"
              />
            }
          />
          <Route
            path="/forms"
            element={
              <ProtectedRoute
                element={FormDashboard}
                permission="view_formsdashboard"
              />
            }
          />
          <Route
            path="/forms/id/:id"
            element={
              <ProtectedRoute element={EditForm} permission="view_forms" />
            }
          />

          {/* Error Routes */}
          <Route path="/not-authorized" element={<NotAuthorized />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/health" element={<Health />} />
      </Routes>
    </>
  );
};

export default Router;
