import React, { createContext, useMemo, useContext, ReactNode } from 'react';
import { useQuery } from '@tanstack/react-query';
import { users } from '@/services/user-service';
import { useCookies } from 'react-cookie';
import { getAuthorizationToken } from '@/services/api-header-options';

// Define the context type
interface AuthContextType {
  isAuthenticated: boolean;
  isAuthLoading: boolean;
  logout: () => any;
  checkIsAuthenticated: () => boolean;
  checkPermission: (permission: string) => boolean;
  isPermissionsLoading: boolean;
}

// Create the context with a default value
const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isAuthLoading: true,
  logout: () => {},
  checkIsAuthenticated: () => false,
  checkPermission: () => false,
  isPermissionsLoading: false,
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [cookies, , removeCookie] = useCookies(['Authorization', 'refresh']);

  // Query to check authentication status
  const { data: isAuthenticated = false, isLoading: isAuthLoading } = useQuery({
    queryKey: ['authToken', cookies.Authorization],
    queryFn: async () => {
      const token = await getAuthorizationToken();
      return !!token;
    },
    enabled: (!!cookies.Authorization) || (!!cookies.refresh), // Only run if the Authorization cookie is present
    refetchOnWindowFocus: false,
  });

  // Query to fetch permissions
  const fetchPermissions = async () => {
    const cachedPermissions = sessionStorage.getItem('permissions');
    if (cachedPermissions) {
      return JSON.parse(cachedPermissions);
    }
    const response = await users.me();
    const permissions = response?.data?.group_permissions || [];
    sessionStorage.setItem('permissions', JSON.stringify(permissions));
    return permissions;
  };

  const {
    data: permissionData,
    isLoading: isPermissionsLoading,
  } = useQuery({
    queryKey: ['me'],
    queryFn: fetchPermissions,
    enabled: isAuthenticated, // Only run if authenticated
    refetchOnWindowFocus: false,
  });

  // Check permission with useMemo to avoid recalculating unless permissionData changes
  const checkPermission = (permission: string) => {
    return permissionData ? permissionData.includes(permission) : false;
  };

  const logout = async () => {
    try {
      // Clear cookies
      removeCookie('Authorization', { path: '/' });
      removeCookie('refresh', { path: '/' });

      // Redirect to the login page
      window.location.href = "/login";
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      isAuthLoading,
      logout,
      checkIsAuthenticated: () => isAuthenticated,
      checkPermission,
      isPermissionsLoading,
    }),
    [isAuthenticated, checkPermission, isPermissionsLoading]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
