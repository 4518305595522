import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { users } from "@/services/user-service";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { format, toZonedTime } from "date-fns-tz";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
  CardDescription,
} from "@/components/ui/card";
import LoadingScreen from "@/components/loading/loading-screen";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useDropzone } from "react-dropzone";
import { IoCloudUploadOutline } from "react-icons/io5";
import { TiDeleteOutline } from "react-icons/ti";
import { Label } from "@/components/ui/label";
import LogFeed from "@/components/log-feed";
import { tickets, comments, categories } from "@/services/tickets-services";
import LoadingIcon from "@/components/loading/loading-icon";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useAuth } from "@/components/context/AuthContext";
import { Separator } from "@/components/ui/separator";
import OnboardingTicketInstruction from "@/components/onboarding-ticket-instruction";

const RequestView = () => {
  const { language } = useLanguage();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [attachment, setAttachment] = useState<any>([]);
  const [noteError, setNoteError] = useState("");
  const [isTicketUpdated, setIsTicketUpdated] = useState<any>(false);
  const [newTicketPriority, setNewTicketPriority] = useState("");
  const [newTicketCategory, setNewTicketCategory] = useState("");
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const { checkPermission } = useAuth();
  const canChangeTickets = checkPermission('change_tickets');
  const [assignedToList, setAssignedToList] = useState<any>([]);
  const [specialRequest] = useState<any>([
    "add_training_certificate",
    "onboarding",
    "offboarding",
    "print_employee_card",
  ]);

  const {
    data: usersData,
    isLoading: usersLoading
  } = useQuery({
    queryKey: ["users"],
    queryFn: () => users.simple().then((data:any) => {
      setAssignedToList(data?.data);
      return data;
    }),
  });

  const {
    data: taskData,
    isLoading: taskLoading,
    isRefetching: taskRefetching,
    refetch: taskRefetch,
  } = useQuery({
    queryKey: ["task", id],
    queryFn: () => tickets.get(id).then((data: any) => {return data}),
    refetchOnWindowFocus: false,
  });

  const {
    data: ticketsOptionData,
    isLoading: ticketsOptionLoading,
  } = useQuery({
    queryKey: ["tickets view ticket"],
    queryFn: tickets.options,
    refetchOnWindowFocus: false,
  });

  const {
    data: categoriesData,
    isLoading: categoriesLoading,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: categories.list,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    document.title = "Lazem GO - IT Task";
  }, []);

  function formatDate(isoDate: any) {
    const timeZone =
      Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Riyadh";
    let formattedDateTime = format(
      toZonedTime(isoDate, timeZone),
      "MMMM d, yyyy hh:mm a",
      { timeZone }
    );
    return formattedDateTime;
  }

  const onDrop = (acceptedFiles: any) => {
    setAttachment((prevState: any[]) => [...prevState, ...acceptedFiles]);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const saveNote = async () => {
    if (!note) {
      setNoteError(language === "en" ? "Note is required" : "الملاحظة مطلوبة");
      return;
    }
    setLoading(true);
    let formData = new FormData();
    if (attachment.length > 0) {
      attachment.forEach((file: any) => {
        formData.append("attachments", file, file.name);
      });
    }
    formData.append("comment", note);
    formData.append("ticket", taskData?.data?.id);
    await comments.create(formData).then(() => {
      setNoteError("");
      window.location.reload();
    }).catch((error) => {
      console.log(error);
    });
  }

  const closeTicket = async () => {
    setLoading(true);
    let data = {
      status: "Closed",
    };
    await tickets.update(taskData?.data?.id, data).then(() => {
      window.location.reload();
    }).catch((error) => {
      console.log(error);
    });
  }

  const reopenTicket = async () => {
    setLoading(true);
    let data = {
      status: "Open",
    };
    await tickets.update(taskData?.data?.id, data).then(() => {
      window.location.reload();
    }).catch((error) => {
      console.log(error);
    });
  }

  const handleUpdate = async () => {
    setLoading(true);
    let data: any = {};
    if (newTicketPriority) {
      data = {
        ...data,
        priority: newTicketPriority
      }
    }
    if (newTicketCategory) {
      data = {
        ...data,
        category: newTicketCategory
      }
    }
    if (selectedUser) {
      data = {
        ...data,
        assigned_to: selectedUser
      }
    }
    await tickets.update(taskData?.data?.id, data).then(() => {
      window.location.reload();
    }).catch((error) => {
      console.log(error);
    });
  }

  const handleUsersFilter = (name: string) => {
    const filteredUsers = usersData?.data?.filter((user: any) =>
      user?.full_name?.toLowerCase().includes(name?.toLowerCase())
    );
    setAssignedToList(filteredUsers);
  };

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {(usersLoading || taskLoading || ticketsOptionLoading) && <LoadingScreen />}

      {taskData &&
        <Card className="w-full lg:w-1/2 mx-auto mb-10">
          <CardHeader>
            <Button
              variant={"secondary"}
              onClick={() => {
                navigate("/requests?" + queryString);
              }}
              className="w-full my-4"
            >
              {language === "en" ? "Back" : "رجوع"}
            </Button>
            <CardTitle>{taskData?.data?.title}</CardTitle>
            {/* <CardDescription>{taskData?.data?.description}</CardDescription> */}
          </CardHeader>
          <div
            className="flex justify-between items-center p-4"
          >
            {
              (taskData?.data?.status === "Closed" || taskData?.data?.status === "In Progress") &&
              <div>
                <Dialog>
                  <DialogTrigger
                  >
                    <Button
                      variant={taskData?.data?.status === "Closed" ? "secondary" : "destructive"}
                      className="w-full"
                      permission="change_tickets"
                    >
                      {taskData?.data?.status === "Closed" ? (language === "en" ? "Reopen" : "إعادة فتح") : (language === "en" ? "Close" : "إغلاق")}
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      {
                        isTicketUpdated && (
                          <div
                            className="w-full text-green-500 p-2 text-center"
                          >
                            {language === "en" ? "Ticket Updated" : "تم تحديث التذكرة"}
                          </div>
                        )
                      }
                      <DialogTitle>
                        {language === "en" ? "Confirmation" : "تأكيد"}
                      </DialogTitle>
                    </DialogHeader>
                    <DialogDescription>
                      {
                        taskData?.data?.status === "Closed" ? (language === "en" ? "Are you sure you want to reopen this ticket?" : "هل أنت متأكد أنك تريد إعادة فتح هذه التذكرة؟") : (language === "en" ? "Are you sure you want to close this ticket?" : "هل أنت متأكد أنك تريد إغلاق هذه التذكرة؟")
                      }
                    </DialogDescription>
                    {
                      taskData?.data?.status === "Closed" ? (
                        <Button
                          onClick={reopenTicket}
                          className="w-full"
                          disabled={loading}
                        >
                          {loading ? <LoadingIcon /> : language === "en" ? "Reopen" : "إعادة فتح"}
                        </Button>
                      ) :
                        <Button
                          onClick={closeTicket}
                          className="w-full"
                          disabled={loading}
                        >
                          {loading ? <LoadingIcon /> : language === "en" ? "Close" : "إغلاق"}
                        </Button>
                    }

                  </DialogContent>
                </Dialog>
              </div>
            }

            <div>
              <Dialog>
                <DialogTrigger className="w-full">
                  <div className="flex justify-start my-6">
                    <Button>{language === "en" ? "Add Note" : "إضافة ملاحظة"}</Button>
                  </div>
                </DialogTrigger>
                <DialogContent dir={language === "en" ? "ltr" : "rtl"}>
                  <DialogHeader>
                    {
                      isTicketUpdated && (
                        <div
                          className="w-full text-green-500 p-2 text-center"
                        >
                          {language === "en" ? "Ticket Updated" : "تم تحديث التذكرة"}
                        </div>
                      )
                    }
                    <DialogTitle>
                      {language === "en" ? "Add Note" : "إضافة ملاحظة"}
                    </DialogTitle>
                  </DialogHeader>
                  <div className="text-start">
                    <Label>{language === "en" ? "Note" : "ملاحظة"}</Label>
                    <Textarea
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                    {/*<!-- Component: Dropzone file input --> */}
                    <div className="relative my-6">
                      <div
                        {...getRootProps({
                          className:
                            "relative flex cursor-pointer flex-col items-center gap-4 rounded border border-dashed border-slate-300 px-3 py-6 text-center text-sm font-medium transition-colors",
                        })}
                      >
                        <input {...getInputProps()} />
                        <IoCloudUploadOutline
                          className="inline-flex h-12 items-center justify-center self-center"
                          size={24}
                        />
                        <span className="text-slate-500">
                          {language === "en"
                            ? "Drag and drop or Browse"
                            : "اسحب أو تصفح"}
                        </span>
                      </div>
                    </div>
                    {attachment.length > 0 && (
                      <div>
                        {attachment.map((file: any) => (
                          <div
                            key={file.path}
                            className="flex gap-6 items-center justify-between my-2"
                          >
                            <span>{file.path}</span>
                            <TiDeleteOutline
                              onClick={() => {
                                setAttachment(
                                  attachment.filter((f: any) => f.path !== file.path)
                                );
                              }}
                              className="text-red-500 cursor-pointer"
                              size={24}
                            />
                          </div>
                        ))}
                      </div>
                    )}

                    {/*<!-- End Dropzone file input --> */}
                  </div>
                  <Button
                    onClick={saveNote}
                    className="w-full"
                    disabled={loading}
                  >
                    {loading ? <LoadingIcon /> : language === "en" ? "Save" : "حفظ"}
                  </Button>
                </DialogContent>
              </Dialog>
            </div>

          </div>
          <CardContent className="text-start">
            {
              isTicketUpdated && (
                <div
                  className="w-full bg-green-500 text-white p-2 text-center"
                >
                  {language === "en" ? "Ticket Updated" : "تم تحديث التذكرة"}
                </div>
              )
            }
            <div className="">
              <div>
                <Label>{language === "en" ? "Status" : "الحالة"}</Label>
              </div>
              <div>
                <Label
                  className={`
                              text-start w-full
                          ${taskData?.data?.status === "Closed"
                      ? "text-green-500"
                      : "text-red-500"
                    }
                          `}
                >
                  {taskData?.data?.status}
                </Label>
              </div>
            </div>
            <div className="my-6">
              <Label>{language === "en" ? "Service Type" : "نوع الخدمة"}</Label>
              <div>{taskData?.data?.service_type}</div>
            </div>

            <div
              className="flex flex-col gap-6"
            >
              <div className="my-2">
                <Label>{language === "en" ? "Requested By" : "طلب بواسطة"}</Label>
                <div>
                  {language === "en" ? taskData?.data?.created_by?.full_name : taskData?.data?.created_by?.full_name_ar || taskData?.data?.created_by?.full_name}
                </div>
              </div>
              <div className="my-2">
                <Label>{language === "en" ? "Created At" : "تاريخ الإنشاء"}</Label>
                <div>{formatDate(taskData?.data?.created_at)}</div>
              </div>
              <div className="my-2">
                <Label>{language === "en" ? "Last Updated" : "آخر تحديث"}</Label>
                <div>{formatDate(taskData?.data?.updated_at)}</div>
              </div>
            </div>
            <div className="my-6">
              <Label className="w-full">
                {language === "en" ? "Assigned To" : "تعيين لـ"}
              </Label>
              <Select
                onValueChange={(value) => setSelectedUser(value)}
                defaultValue={taskData?.data?.assigned_to?.id || ""}
                disabled={!canChangeTickets}
              >
                <SelectTrigger>
                  <SelectValue
                    placeholder={
                      language === "en" ? "Select User" : "اختر مستخدم"
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {/* //add an input to search for users */}
                    <Input
                      placeholder={language === "en" ? "Search" : "بحث"}
                      onChange={(e) => {
                        e.preventDefault()
                        handleUsersFilter(e.target.value)
                      }}
                    />
                    {assignedToList &&
                      assignedToList?.map((user: any) => (
                        <SelectItem key={user.id} value={user.id}>
                          {user.full_name}
                        </SelectItem>
                      ))
                    }
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div>
              <Label>
                {language === 'en' ? "Category" : "الفئة"}
              </Label>
              <Select
                onValueChange={(value) => setNewTicketCategory(value)}
                defaultValue={taskData?.data?.category?.id}
                disabled={!canChangeTickets || specialRequest.includes(taskData?.data?.service_type)}
              >
                <SelectTrigger>
                  <SelectValue
                    placeholder={
                      language === "en" ? "Select Category" : "اختر الفئة"
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {categoriesData && categoriesData.data?.map((category: any) => (
                      <SelectItem key={category.id} value={category.id}>
                        {category.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div
              className="my-6"
            >
              <Label>
                {language === 'en' ? "Priority" : "الأولوية"}
              </Label>
              <RadioGroup
                onValueChange={(value) => setNewTicketPriority(value)}
                dir={language === 'en' ? 'ltr' : 'rtl'}
                defaultValue={taskData?.data?.priority}
                disabled={!canChangeTickets}
              >
                {
                  ticketsOptionData && ticketsOptionData?.data?.actions?.POST?.priority?.choices?.map((priority: any) => (
                    <div
                      key={priority?.value}
                      className="flex items-center gap-2"
                    >
                      <RadioGroupItem
                        key={priority?.value}
                        id={priority?.value}
                        value={priority?.value}
                      />
                      <Label
                        htmlFor={priority?.value}
                      >
                        {priority?.display_name}
                      </Label>
                    </div>
                  ))
                }
              </RadioGroup>
            </div>
            <Button
              className="w-full"
              disabled={(!selectedUser && !newTicketPriority && !newTicketCategory) || loading}
              onClick={handleUpdate}
              permission="change_tickets"
            >
              {language === "en" ? "Save" : "حفظ"}
            </Button>
            <div className="my-6">
              <div
                className="mb-6"
              >

                <Separator />
              </div>
              <Label
                className="w-full text-start text-lg font-bold"
              >
                {language === "en" ? "Ticket Information" : "معلومات التذكرة"}
              </Label>
              <div className="grid gap-4">
                {
                  taskData.data.service_type === "onboarding" ?
                  <OnboardingTicketInstruction onboarding_info={taskData?.data?.json_request} />
                  :
                  taskData?.data?.json_request?.map((question: any, index: any) => (
                    <div key={index} className="w-full">
                      <Label className="font-bold">
                        {language === "en" ? question.question_en : question.question_ar}
                      </Label>
                      <p className="text-gray-600" style={{ whiteSpace: 'pre-line' }}>
                        {question.answer}
                      </p>
                    </div>
                  ))
                }
              </div>

            </div>
          </CardContent>
          {taskData?.data?.comments && <LogFeed logs={taskData?.data?.comments} />}
        </Card>
      }
    </div>
  );
};

export default RequestView;
