import { useLanguage } from "@/components/context/LanguageContext";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingScreen from "@/components/loading/loading-screen";
import LoadingIcon from "@/components/loading/loading-icon";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import LogFeed from "@/components/log-feed";
import { getVehicle, getVehicleStatus, updateVehicle } from "@/services/fleet-services";
import { getSites } from "@/services/site-services";
import { FiRefreshCw } from "react-icons/fi";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@/components/context/AuthContext";


const ManageVehicle = () => {
    const navigate = useNavigate();
    const { language } = useLanguage();
    const { id }: any = useParams();
    const [vehicle, setVehicle] = useState<any>();
    const [updatedFields, setUpdatedFields] = useState<any>();
    const [formLoading, setFormLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formError, setFormError] = useState('');
    const [vehicleStatus, setVehicleStatus] = useState<any>([]);
    const [isVehicleStatusFetched, setIsVehicleStatusFetched] = useState(false);
    const [sites, setSites] = useState<any>([]);
    const { checkPermission } = useAuth();
    const canEdit = checkPermission('change_vehicles');
    const canAddStatus = checkPermission('add_status');
    const canAddSite = checkPermission('add_sites');

    useEffect(() => {
        document.title = 'Lazem GO - Manage Vehicle';
    }, []);

    const fetchVehicle = async () => {
        await getVehicle(id).then((response: any) => {
            setVehicle(response.data);
        }).catch((error) => {
            console.log(error);
        })
    };

    const fetchVehicleStatus = async () => {
        setIsVehicleStatusFetched(true);
        await getVehicleStatus().then((response: any) => {
            setVehicleStatus(response.data);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setIsVehicleStatusFetched(false);
        });
    }

    const fetchSites = async () => {
        await getSites().then((response: any) => {
            setSites(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }

    const fetchData = async () => {
        if (!vehicle) {
            await fetchVehicle();
            await fetchSites();
            await fetchVehicleStatus();
        }
    }

    const { isLoading: vehicleLoading } = useQuery({
        queryKey: ['vehicle', id],
        queryFn: fetchData,
        refetchOnWindowFocus: false,
    });

    const setInputField = (label: string, field: string, value: any, type?: string) => {
        return (
            <div className="flex flex-col my-4">
                <Label
                    className="mb-2"
                >
                    {label}
                </Label>
                <Input
                    type={type ? type : 'text'}
                    value={value}
                    onChange={(e) => {
                        setUpdatedFields({ ...updatedFields, [field]: e.target.value })
                        setVehicle({ ...vehicle, [field]: e.target.value })
                    }}
                    disabled={!isEditing}
                />
            </div>
        );
    }


    const setSelectField = (label: string, field: string, options: any, with_add?: boolean, url?: string) => {
        return (
            <div className="flex flex-col my-6">
                <Label
                    className="mb-1"
                >
                    {label}
                </Label>
                <div
                    className="flex gap-2"
                >
                    <div
                        className={`${with_add ? 'w-4/5 ' : 'w-full'}`}
                    >
                        <Select
                            onValueChange={(value: any) => {
                                setUpdatedFields({ ...updatedFields, [field]: value })
                                setVehicle({ ...vehicle, [field]: value })
                            }}
                            disabled={!isEditing}
                            defaultValue={vehicle[field] ? (vehicle[field]['id'] ? vehicle[field]['id'] : vehicle[field]) : ''}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder={language === 'en' ? 'Select' + label : 'اختر' + label} />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>
                                        {language === 'en' ? 'Select' + label : 'اختر' + label}
                                    </SelectLabel>
                                    {options?.map((option: any) => (
                                        <SelectItem
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {
                                                field === 'status' ? option.title : option.name
                                            }
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        {with_add &&
                            <Button
                                variant="outline"
                                onClick={() => functionToCall(field)}
                                className=""
                            >
                                <FiRefreshCw />

                            </Button>
                        }
                    </div>
                </div>
                {
                    with_add &&
                    <Button
                        variant="link"
                        className="text-sm flex justify-start w-fit"

                        onClick={() => window.open(url ? url : '', '_blank')}
                    >
                        {language === 'en' ? 'Add New' : 'إضافة جديد'}
                    </Button>
                }
            </div>
        );
    }

    const functionToCall = (field: string) => {
        if (field === 'status') {
            fetchVehicleStatus();
        }
        else if (field === 'site') {
            fetchSites();
        }
    }

    const checkForm = () => {
        if (!updatedFields) {
            setFormError(language === 'en' ? 'No fields updated' : 'لم يتم تحديث أي حقل');
            return false;
        }
        return true
    }

    const handelUpdate = async () => {
        if (!checkForm()) {
            return;
        }
        setFormLoading(true);
        setFormError('');
        await updateVehicle(id, updatedFields).then(() => {
            setVehicle(vehicle);
            setIsEditing(false);
            window.location.reload();
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setFormLoading(false);
        });
    }

    return (
        <div
            dir={language === "en" ? "ltr" : "rtl"}
        >
            {vehicleLoading && <LoadingScreen />}
            {
                vehicle &&
                <Card
                    className="w-full lg:w-[800px]  m-auto"
                >
                    <CardHeader>
                        <Button
                            onClick={() => navigate('/fleet')}
                            className="flex w-full my-6"
                            variant={"secondary"}
                        >
                            {language === 'en' ? 'Back' : 'رجوع'}
                        </Button>
                        <CardTitle>
                            {language === 'en' ? 'Manage Vehicle' : 'إدارة المركبة'}
                        </CardTitle>
                        {canEdit &&

                            <Button
                                onClick={() => setIsEditing(!isEditing)}
                                className="w-fit"
                                variant={isEditing ? 'destructive' : 'default'}

                            >
                                {isEditing ? language === 'en' ? 'Cancel' : 'إلغاء' : language === 'en' ? 'Edit' : 'تعديل'}
                            </Button>
                        }
                    </CardHeader>
                    <CardContent
                        className="flex flex-col text-start"
                    >
                        {setInputField(language === 'en' ? 'Code' : 'الرمز', 'code', vehicle?.code)}
                        {setInputField(language === 'en' ? 'Description' : 'الوصف', 'description', vehicle?.description)}
                        {setInputField(language === 'en' ? 'Brand' : 'الماركة', 'brand', vehicle?.brand)}
                        {setInputField(language === 'en' ? 'Manufacturing Year' : 'سنة التصنيع', 'manufacturing_year', vehicle?.manufacturing_year, 'date')}
                        {setInputField(language === 'en' ? 'VIN' : 'رقم الهيكل', 'vin', vehicle?.vin)}
                        {setInputField(language === 'en' ? 'Plate Number' : 'رقم اللوحة', 'plate_number', vehicle?.plate_number)}
                        {setSelectField(language === 'en' ? 'Status' : 'الحالة', 'status', vehicleStatus, canAddStatus, '/fleet/create-status')}
                        {setSelectField(language === 'en' ? 'Site' : 'الموقع', 'site', sites, canAddSite, '/sites/create')}
                    </CardContent>
                    <CardFooter
                        className="flex flex-col items-start gap-4"
                    >
                        {formError &&
                            <div
                                className="text-red-500 font-semibold "
                            >
                                {formError}
                            </div>
                        }
                        {isEditing && (
                            <Button
                                onClick={() => handelUpdate()}
                                className="w-full"
                            >
                                {formLoading ?
                                    <LoadingIcon />
                                    :
                                    language === 'en' ? 'Save' : 'حفظ'
                                }
                            </Button>
                        )}

                        {/* <LogFeed
                            logs={vehicleLogs}
                        /> */}
                    </CardFooter>
                </Card>
            }
        </div>
    );
}

export default ManageVehicle;