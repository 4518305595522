import { useLanguage } from "@/components/context/LanguageContext";
import { getMyProfile, users } from "@/services/user-service";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import LoadingScreen from "@/components/loading/loading-screen";
import { useQuery } from "@tanstack/react-query";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { is_arabic, is_english } from "@/services/language-checker";
import UpdateProfilePhotoComponent from "@/components/update-profile-photo-component";

const Profile = () => {
    const [systemError, setSystemError] = useState('');
    const [profile, setProfile] = useState<any>();
    const { language } = useLanguage();
    const navigate = useNavigate();
    const [formLoading, setFormLoading] = useState(false);
    const [formError, setFormError] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [updateProfile, setUpdateProfile] = useState<any>({});


    useEffect(() => {
        document.title = 'Lazem GO - Profile';
    }, []);

    const fetchProfile = async () => {
        if (!profile) {
            await getMyProfile().then((response: any) => {
                setProfile(response.data);
            }).catch((error: any) => {
                setSystemError(error.message);
            })
        }
    }

    const { isLoading: profileLoading } = useQuery({
        queryKey: ['profile'],
        queryFn: fetchProfile,
        refetchOnWindowFocus: false
    });

    const checkUpdatedFields = () => {
        let result = true;
        if (!updateProfile || Object.keys(updateProfile).length === 0) {
            setFormError(language === 'en' ? 'Please update at least one field' : 'الرجاء تحديث حقل واحد على الأقل');
            result = false;
        }
        else {
            for (const key in updateProfile) {
                if (key === "first_name" || key === "middle_name" || key === "last_name") {
                    if (!is_english(updateProfile[key])) {
                        setFormError(language === 'en' ? 'English fields should contain only English characters' : 'حقول الانجليزية يجب أن تحتوي على حروف انجليزية فقط');
                        result = false;
                    }
                }
                else if (key === "first_name_ar" || key === "middle_name_ar" || key === "last_name_ar") {
                    if (!is_arabic(updateProfile[key])) {
                        setFormError(language === 'en' ? 'Arabic fields should contain only Arabic characters' : 'حقول العربية يجب أن تحتوي على حروف عربية فقط');
                        result = false;
                    }
                }
            }
        }
        return result;
    }

    const updateProfileData = async () => {
        console.log(checkUpdatedFields());
        if (checkUpdatedFields()) {
            setFormError('');
            setFormLoading(true);
            await users.update_me(updateProfile).then((response: any) => {
                setProfile(response.data);
                setFormLoading(false);
                setIsEdit(false);
            }).catch((error: any) => {
                setSystemError(error.message);
                setFormLoading(false);
            })
        }
    }

    const setInputField = (label: string, field: any, value: any, type?: string) => {
        return (
            <div className="flex flex-col my-4 text-start">
                <Label className="mb-2">
                    {label}
                </Label>
                <Input
                    type={type ? type : 'text'}
                    value={value}
                    disabled={true}
                />
            </div>
        )
    }

    const setEditableInputField = (
        label: string,
        field: string,
        value: string,
        type: string = 'text',
        needUpdate: boolean = false,
        validationFor?: 'english' | 'arabic'
    ) => {
        const validationMessage = () => {
            if (!validationFor || value === '') return null;

            const isInvalid =
                (validationFor === 'english' && !is_english(value)) ||
                (validationFor === 'arabic' && !is_arabic(value));

            if (isInvalid) {
                return (
                    <span className="text-red-500">
                        {language === 'en'
                            ? validationFor === 'english'
                                ? 'only English characters'
                                : 'only Arabic characters'
                            : validationFor === 'english'
                                ? 'حروف انجليزية فقط'
                                : 'حروف عربية فقط'}
                    </span>
                );
            }
            return null;
        };

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;
            setUpdateProfile((prev: any) => {
                const updatedField = field === 'phone_number' || field === 'address'
                    ? { profile_user: { ...prev.profile_user, [field]: newValue } }
                    : { [field]: newValue };
                return { ...prev, ...updatedField };
            });
        };

        return (
            <div className="flex flex-col my-4 text-start">
                <Label className="mb-2">
                    {label}{' '}
                    {needUpdate && (
                        <span className="text-red-500">
                            {language === 'en' ? 'Please update' : 'الرجاء التحديث'}
                        </span>
                    )}{' '}
                    {validationMessage()}
                </Label>
                <Input
                    type={type}
                    defaultValue={value}
                    disabled={!isEdit}
                    onChange={handleInputChange}
                />
            </div>
        );
    };

    const setEditableRadioField = (label: any, field: any, value: any, options?: any, need_update?: boolean) => {
        return (
            <div className="flex flex-col my-4 text-start">
                <Label className="mb-2">
                    {label} {need_update && (
                        language === 'en' ? (
                            <>
                                <span className="text-red-500">
                                    Please update
                                </span>
                            </>
                        ) : (
                            <>
                                <span className="text-red-500">الرجاء التحديث</span>
                            </>
                        )
                    )}
                </Label>
                <RadioGroup
                    onValueChange={(value) => {
                        setProfile((prev: any) => ({
                            ...prev,
                            profile_user: {
                                ...prev.profile_user,
                                [field]: value
                            }
                        }));
                        setUpdateProfile((prev: any) => ({
                            ...prev,
                            profile_user: {
                                ...prev.profile_user,
                                [field]: value
                            }
                        }));
                    }}
                    defaultValue={value}
                    disabled={!isEdit}
                    className="flex flex-col space-y-4 justify-start text-start"
                >
                    {options && options.map((option: any) =>
                        <div key={option} className={`flex space-x-2 ${language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}>
                            <RadioGroupItem value={option} id={option} className="mx-2" disabled={!isEdit} />
                            <Label htmlFor={option}>
                                {option}
                            </Label>
                        </div>
                    )}
                </RadioGroup>
            </div>
        )
    }

    return (
        <div dir={language === 'en' ? 'ltr' : 'rtl'}>
            {profileLoading && systemError === '' && <LoadingScreen />}
            {
                profile &&
                <>
                    <Card
                        className="w-11/12 mx-auto mt-8 lg:w-1/2"
                    >
                        <CardHeader>
                            <Button onClick={() => navigate('/')} className="w-full my-4" variant={"secondary"}>
                                {language === 'en' ? 'Home' : 'الرئيسية'}
                            </Button>
                            <CardTitle
                                className="my-4"
                            >
                                {language === 'en' ? 'Profile' : 'الملف الشخصي'}
                            </CardTitle>
                            <UpdateProfilePhotoComponent
                                profile={profile}
                            />
                            {
                                !profile?.staff_profile?.photo &&
                                <p className="text-center text-red-500 text-lg font-bold">
                                    {language === 'en' ? 'Please upload a photo' : 'الرجاء رفع صورة'}
                                </p>
                            }
                            <Button
                                onClick={() => setIsEdit(!isEdit)}
                                className="my-4 w-fit"
                                variant={isEdit ? 'destructive' : 'default'}
                            >
                                {isEdit ? (language === 'en' ? 'Cancel' : 'إلغاء') : (language === 'en' ? 'Edit' : 'تعديل')}
                            </Button>
                        </CardHeader>
                        <CardContent>
                            <div className="text-start space-y-4 flex flex-col">
                                {setEditableInputField(language === 'en' ? 'First Name (English)' : ' الإسم الأول (بالانجليزية)', 'first_name', profile?.first_name, 'text', !profile?.first_name, 'english')}
                                {setEditableInputField(language === 'en' ? 'Middle Name (English)' : ' الإسم الأوسط (بالانجليزية)', 'middle_name', profile?.middle_name, 'text', !profile?.middle_name, 'english')}
                                {setEditableInputField(language === 'en' ? 'Last Name(English)' : ' الإسم الأخير (بالانجليزية)', 'last_name', profile?.last_name, 'text', !profile?.last_name, 'english')}

                                {setEditableInputField(language === 'en' ? 'First Name (Arabic)' : ' الإسم الأول (بالعربية)', 'first_name_ar', profile?.first_name_ar, 'text', !profile?.first_name_ar, 'arabic')}
                                {setEditableInputField(language === 'en' ? 'Middle Name (Arabic)' : ' الإسم الأوسط (بالعربية)', 'middle_name_ar', profile?.middle_name_ar, 'text', !profile?.middle_name_ar)}
                                {setEditableInputField(language === 'en' ? 'Last Name (Arabic)' : ' الإسم الأخير (بالعربية)', 'last_name_ar', profile?.last_name_ar, 'text', !profile?.last_name_ar)}

                                {setEditableInputField(language === 'en' ? 'Phone' : 'الهاتف', 'phone_number', profile?.profile_user?.phone_number || '')}
                                {setEditableInputField(language === 'en' ? 'Address' : 'العنوان', 'address', profile?.profile_user?.address || '')}
                                {setEditableRadioField(language === 'en' ? 'Gender' : 'الجنس', 'gender', profile?.profile_user?.gender || '', ['Male', 'Female'], !profile?.profile_user?.gender)}

                                <CardFooter
                                    className="flex flex-col"
                                >
                                    {formError !== '' && <p className="text-red-500 text-sm font-bold">{formError}</p>}
                                    {isEdit &&
                                        <Button onClick={() => updateProfileData()} className="w-full my-4">
                                            {formLoading ? <LoadingScreen /> : language === 'en' ? 'Save' : 'حفظ'}
                                        </Button>
                                    }
                                </CardFooter>

                                <Card
                                    className="w-full p-1"
                                >
                                    <CardTitle
                                        className="text-sm text-center mt-4"
                                    >
                                        {language === 'en' ? 'Contact HR department to update the following fields' : 'تواصل مع قسم الموارد البشرية لتحديث الحقول التالية'}
                                    </CardTitle>
                                    <CardContent
                                        className="mt-6"
                                    >
                                        {setInputField(language === 'en' ? 'Employee ID' : 'الرقم الوظيفي', 'employee_id', profile?.staff_profile?.employee_id || '')}
                                        {setInputField(language === 'en' ? 'Email' : 'البريد الإلكتروني', 'email', profile.email)}
                                        {setInputField(language === 'en' ? 'Department' : 'القسم', 'department', profile?.staff_profile?.department?.title || '')}
                                        {setInputField(language === 'en' ? 'Role' : 'الدور', 'role', profile?.staff_profile?.role?.title || '')}
                                    </CardContent>
                                </Card>
                            </div>
                        </CardContent>
                    </Card>
                </>
            }
        </div>
    );
}

export default Profile;
