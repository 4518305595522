import { useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { RadioGroupItem, RadioGroup } from "@/components/ui/radio-group";
import LoadingIcon from "@/components/loading/loading-icon";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "@/components/context/LanguageContext";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { departments } from "@/services/project-service";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";
import { tickets, categories } from "@/services/tickets-services";

const SupportOnboardingForm = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [onboardingForm, setOnboardingForm] = useState<any>({
    title: "On Boarding",
    service_type: "onboarding",
    description: "On Boarding Form",
    priority: "High",
    json_request: [
      {
        question_en: "First Name (English)",
        question_ar: "الاسم الأول (الإنجليزية)",
        answer: "",
        is_required: true,
        name: "english_first_name",
      },
      {
        question_en: "Middle Name (English)",
        question_ar: "الاسم الأوسط (الإنجليزية)",
        answer: "",
        is_required: false,
        name: "english_middle_name",
      },
      {
        question_en: "Last Name (English)",
        question_ar: "الاسم الأخير (الإنجليزية)",
        answer: "",
        is_required: true,
        name: "english_last_name",
      },
      {
        question_en: "First Name (Arabic)",
        question_ar: "الاسم الأول (العربية)",
        answer: "",
        is_required: true,
        name: "arabic_first_name",
      },
      {
        question_en: "Middle Name (Arabic)",
        question_ar: "الاسم الأوسط (العربية)",
        answer: "",
        is_required: false,
        name: "arabic_middle_name",
      },
      {
        question_en: "Last Name (Arabic)",
        question_ar: "الاسم الأخير (العربية)",
        answer: "",
        is_required: true,
        name: "arabic_last_name",
      },
      {
        question_en: "Phone",
        question_ar: "الهاتف",
        answer: "",
        is_required: true,
        name: "phone",
      },
      {
        question_en: "Gender",
        question_ar: "الجنس",
        answer: "",
        is_required: false,
        name: "gender",
      },
      {
        question_en: "IT Services",
        question_ar: "خدمات تقنية المعلومات",
        answer: "",
        is_required: false,
        name: "it_services",
      },
      {
        question_en: "IT Note",
        question_ar: "ملاحظة تقنية المعلومات",
        answer: "",
        is_required: false,
        name: "it_note",
      },
      {
        question_en: "General Note",
        question_ar: "ملاحظة عامة",
        answer: "",
        is_required: false,
        name: "general_note",
      },
      {
        question_en: "Department",
        question_ar: "القسم",
        answer: "",
        is_required: true,
        name: "department",
      },
      {
        question_en: "Role",
        question_ar: "الدور",
        answer: "",
        is_required: true,
        name: "role",
      }
    ],
    category: null,
  });
  const [itServices] = useState([
    {
      title_en: "Microsoft 365 (Word, Excel, PowerPoint ...)",
      title_ar: "مايكروسوفت 365 (Word, Excel, PowerPoint ...)",
      value: "microsoft_365",
    },
    {
      title_en: "Mouse + Keyboard",
      title_ar: "فأرة + لوحة مفاتيح",
      value: "mouse_keyboard",
    },
    {
      title_en: "Monitor",
      title_ar: "شاشة",
      value: "monitor",
    },
    {
      title_en: "Laptop",
      title_ar: "لابتوب",
      value: "laptop",
    },
    {
      title_en: "Desktop",
      title_ar: "كمبيوتر مكتبي",
      value: "desktop",
    },
  ]);
  const [formErrors, setFormErrors] = useState<any>("");

  const { data: departmentsData, isLoading: departmentsLoading } = useQuery({
    queryKey: ["departments"],
    queryFn: departments.list,
    refetchOnWindowFocus: false,
  });



  const {
    data: categoriesData,
    isLoading: categoriesLoading,
  } = useQuery({
    queryKey: ["categories onboarding"],
    queryFn: () => categories.getWithFilter("tag=on_boarding").then((response:any) => {
      if (response?.data?.length !== 0) {
        setOnboardingForm({
          ...onboardingForm,
          category: response?.data[0]?.id,
        });
      }
      return response.data;
    }),
    refetchOnWindowFocus: false,
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    let updatedForm = onboardingForm.json_request.map((item: any) => {
      if (item.name === name) {
        return { ...item, answer: value };
      }
      return item;
    });
    setOnboardingForm({ ...onboardingForm, json_request: updatedForm });
  };

  const checkRequiredFields = () => {
    let requiredFields = onboardingForm.json_request.filter(
      (item: any) => item.is_required && !item.answer
    );
    if (requiredFields.length > 0) {
      setFormErrors(
        language === "en"
          ? "Please fill out all required fields."
          : "يرجى ملء جميع الحقول المطلوبة."
      );
      window.scrollTo(0, 0);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: any) => {
    if (!checkRequiredFields()) {
      return;
    }
    let data_to_send = { ...onboardingForm };
    data_to_send.title = "On Boarding" + " - " + onboardingForm.json_request.find((item: any) => item.name === "english_first_name")?.answer + " " + onboardingForm.json_request.find((item: any) => item.name === "english_last_name")?.answer;
    setFormErrors("");
    e.preventDefault();
    setLoading(true);
    tickets.create(data_to_send).then((response:any) => {
      navigate("/my-requests/id/" + response?.data?.id);
    }).catch((error: any) => {
      console.log('Form Error', error);
      throw error;
    }).finally(() => {
      setLoading(false);
    });
  };

  const setInputFieldDev = (
    name: string,
    placeholder: string,
    is_required: boolean
  ) => {
    const field = onboardingForm.json_request.find(
      (item: any) => item.name === name
    );
    const value = field ? field.answer : "";

    return (
      <div className="flex flex-col items-center justify-center gap-4 w-11/12 my-6">
        <Label className="w-full ">
          {placeholder}{" "}
          {is_required && <span className="text-red-500"> *</span>}
        </Label>
        <Input
          className="mt-[-8px]"
          name={name}
          value={value}
          onChange={handleChange}
        />
      </div>
    );
  };

  const handleCheckboxChange = (serviceValue: string, checked: any) => {
    let updatedServices =
      onboardingForm.json_request.find((item: any) => item.name === "it_services")
        ?.answer || [];

    if (checked) {
      updatedServices.push(serviceValue);
    } else {
      updatedServices = updatedServices.filter(
        (item: any) => item !== serviceValue
      );
    }

    let updatedForm = onboardingForm.json_request.map((item: any) => {
      if (item.name === "it_services") {
        return { ...item, answer: updatedServices };
      }
      return item;
    });

    setOnboardingForm({
      ...onboardingForm,
      json_request: updatedForm,
    });
  };

  const handleSelectChange = (value: string, name: string) => {
    let updatedForm = onboardingForm.json_request.map((item: any) => {
      if (item.name === name) {
        return { ...item, answer: value };
      }
      return item;
    });
    setOnboardingForm({ ...onboardingForm, json_request: updatedForm });
  };

  return (
    <div
      className="flex flex-col items-center justify-center"
      dir={language === "en" ? "ltr" : "rtl"}
    >
      {(departmentsLoading || categoriesLoading) && <LoadingScreen />}
      {
        <Card className="flex flex-col items-center justify-center gap-4 w-full my-4 p-4 lg:w-1/2">
          <CardHeader
            className="w-full"
          >
            <Button
              onClick={() => navigate("/special-request")}
              className="w-full my-4"
              variant={"secondary"}
            >
              {language === "en" ? "Back" : "عودة"}
            </Button>
            <CardTitle>
              {language === "en" ? "Onboarding Form" : "نموذج ابتداء العمل"}
            </CardTitle>
          </CardHeader>
          <CardContent className="text-start w-full">
            <CardDescription className="text-center">
              {language === "en"
                ? "Please fill out the form below to complete the onboarding process."
                : "يرجى ملء النموذج أدناه لإكمال عملية البدء في العمل."}

              {formErrors && (
                <div className="text-red-500 my-4">{formErrors}</div>
              )}
            </CardDescription>

            {setInputFieldDev(
              "english_first_name",
              language === "en"
                ? "First Name (English)"
                : "الاسم الأول (الإنجليزية)",
              true
            )}
            {setInputFieldDev(
              "english_middle_name",
              language === "en"
                ? "Middle Name (English)"
                : "الاسم الأوسط (الإنجليزية)",
              false
            )}
            {setInputFieldDev(
              "english_last_name",
              language === "en"
                ? "Last Name (English)"
                : "الاسم الأخير (الإنجليزية)",
              true
            )}
            {setInputFieldDev(
              "arabic_first_name",
              language === "en"
                ? "First Name (Arabic)"
                : "الاسم الأول (العربية)",
              true
            )}
            {setInputFieldDev(
              "arabic_middle_name",
              language === "en"
                ? "Middle Name (Arabic)"
                : "الاسم الأوسط (العربية)",
              false
            )}
            {setInputFieldDev(
              "arabic_last_name",
              language === "en"
                ? "Last Name (Arabic)"
                : "الاسم الأخير (العربية)",
              true
            )}
            {setInputFieldDev(
              "phone",
              language === "en" ? "Phone" : "الهاتف",
              true
            )}

            <div className="flex flex-col items-center justify-center gap-4 w-11/12 my-4">
              <Label className="w-full">
                {language === "en" ? "Department" : "القسم"}{" "}
                <span className="text-red-500"> * </span>
              </Label>
              <Select
                name="department"
                onValueChange={(value) => {
                  handleSelectChange(value, "department");
                }}
              >
                <SelectTrigger>
                  <SelectValue
                    placeholder={
                      language === "en" ? "Select Department" : "اختر القسم" 
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  {departmentsData?.data?.map((department: any, index: number) => (
                    <SelectGroup key={department.id}>
                      <SelectItem value={department.title}>
                        {department.title}
                      </SelectItem>
                    </SelectGroup>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="flex flex-col items-center justify-center gap-4 w-11/12 my-4">
              <Label className="w-full">
                {language === "en" ? "Role" : "الدور"}{" "}
                <span className="text-red-500"> *</span>

                {onboardingForm.json_request.find((item: any) => item.name === "department")?.answer === "" && (
                  language === "en"
                    ? " (Please select a department first.) "
                    : " (يرجى تحديد قسم أولاً.) "
              )}
              </Label>
              <Select
                name="role"
                onValueChange={(value) => {
                  handleSelectChange(value, "role");
                }}
                disabled={onboardingForm.json_request.find((item: any) => item.name === "department")?.answer === ""}
              >
                <SelectTrigger>
                  <SelectValue
                    placeholder={language === "en" ? "Select Role" : "اختر الدور"}
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {
                      departmentsData?.data?.find((department: any) => department.title === onboardingForm.json_request.find((item: any) => item.name === "department")?.answer)?.roles.map((role: any, index: number) => (
                        <SelectItem key={role?.id} value={role?.title}>
                          {role?.title}
                        </SelectItem>
                      ))
                    }
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>


            <div className="flex flex-col items-center justify-center gap-4 w-11/12 my-4">
              <Label className="w-full">
                {language === "en" ? "Gender" : "الجنس"}
              </Label>
              <RadioGroup
                className="w-full"
                onValueChange={(value) => {
                  let updatedForm = onboardingForm.json_request.map(
                    (item: any) => {
                      if (item.name === "gender") {
                        return { ...item, answer: value };
                      }
                      return item;
                    }
                  );
                  setOnboardingForm({
                    ...onboardingForm,
                    json_request: updatedForm,
                  });
                }}
              >
                <div
                  className={`flex space-x-2 ${language === "en" ? "flex-row" : "flex-row-reverse"
                    }`}
                >
                  <RadioGroupItem value={"male"} id={"male"} className="mx-2" />
                  <Label htmlFor={"male"}>
                    {language === "en" ? "Male" : "ذكر"}
                  </Label>
                </div>
                <div
                  className={`flex space-x-2 ${language === "en" ? "flex-row" : "flex-row-reverse"
                    }`}
                >
                  <RadioGroupItem value="female" id="female" className="mx-2" />
                  <Label htmlFor={"female"}>
                    {language === "en" ? "Female" : "أنثى"}
                  </Label>
                </div>
              </RadioGroup>
            </div>

            <div className="flex flex-col items-center justify-center gap-4 w-11/12 mt-8">
              <Label className="w-full">
                {language === "en" ? "IT Services" : "خدمات تقنية المعلومات"} (
                {language === "en"
                  ? "these services would cost extra charges for the company"
                  : "هذه الخدمات ستكلف الشركة رسوم إضافية"}
                )
              </Label>
              {itServices.map((service, index) => (
                <div key={index} className="flex items-center gap-2 w-full">
                  <Checkbox
                    onCheckedChange={(checked) => {
                      handleCheckboxChange(service.value, checked);
                    }}
                    className="mx-2 my-2"
                  />
                  <Label>
                    {language === "en" ? service.title_en : service.title_ar}
                  </Label>
                </div>
              ))}
            </div>

            <div className="flex flex-col items-center justify-center gap-4 w-11/12 my-8">
              <Label className="w-full">
                {language === "en" ? "IT Note" : "ملاحظة تقنية المعلومات"}
              </Label>
              <Textarea
                name="it_note"
                value={onboardingForm.json_request.it_note}
                placeholder={
                  language === "en" ? "IT Note" : "ملاحظة تقنية المعلومات"
                }
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col items-center justify-center gap-4 w-11/12 my-4">
              <Label className="w-full">
                {language === "en" ? "General Note" : "ملاحظة عامة"}
              </Label>
              <Textarea
                name="general_note"
                value={onboardingForm.json_request.general_note}
                placeholder={language === "en" ? "General Note" : "ملاحظة عامة"}
                onChange={handleChange}
              />
            </div>
          </CardContent>

          <Button onClick={handleSubmit} disabled={loading}>
            {loading ? <LoadingIcon /> : language === "en" ? "Submit" : "إرسال"}
          </Button>
        </Card>
      }
    </div>
  );
};

export default SupportOnboardingForm;
