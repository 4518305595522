import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { useLanguage } from "./context/LanguageContext";
import { useState } from "react";
import { FcSupport } from "react-icons/fc";
import { MdOutlineFiberNew } from "react-icons/md";
import { MdOutlineSelfImprovement } from "react-icons/md";
import { MdTipsAndUpdates } from "react-icons/md";

const WhatIsNew = ({only_last_update}:any) => {
    const { language } = useLanguage();
    const [updateNews] = useState<any>([
        {
            title: language === "en" ? "What's new in Lazem GO?" : "ما الجديد في لازِم GO؟",
            version: "1.1",
            release_date: "2024-10-16",
            update_details: [
                {
                    title: language === "en" ? "New Features" : "ميزات جديدة",
                    icon: <MdOutlineFiberNew size={40} />,
                    content: [
                        {
                            title: language === "en" ? "Ticketing System" : "نظام التذاكر",
                            description: language === "en" 
                                ? "In this update, users can open tickets to contact the support team. Each ticket includes details such as creation date, responsible team member, and a log of ticket history."
                                : "في هذا التحديث، يمكن للمستخدمين فتح تذاكر للتواصل مع فريق الدعم. تحتوي كل تذكرة على تفاصيل مثل تاريخ الإنشاء والمسؤول وسجل التذكرة.",
                        },
                        {
                            title: language === "en" ? "Special Ticketing System" : "نظام التذاكر الخاص",
                            description: language === "en" 
                                ? "Features similar to the regular ticket system but dedicated to the HR department and used to register a new employee, terminate an employee's contract, and create new training certificates."
                                : "ميزات مشابهة لنظام التذاكر العادي، ولكن مخصصة لقسم الموارد البشرية وتستخدم في تسجيل موظف جديد، انهاء عقد موظف، وإنشاء شهادات تدريب جديدة.",
                        },
                        {
                            title: language === "en" ? "Notification System" : "نظام الإشعارات",
                            description: language === "en" 
                                ? "Create email templates and link them to specific actions, such as sending an email when a project is created or when a user is updated."
                                : "يمكنك إنشاء قوالب بريد إلكتروني وربطها بإجراءات محددة، مثل إرسال بريد إلكتروني عند إنشاء مشروع أو عند تحديث مستخدم.",
                        },
                        {
                            title: language === "en" ? "Form Integration with Lazem.sa" : "ربط نموذج lazem.sa مع النظام",
                            description: language === "en" 
                                ? "Connect the Lazem.sa form with the GO system to automatically create a ticket whenever a new submission is made."
                                : "ربط نموذج Lazem.sa مع نظام GO لإنشاء تذكرة تلقائيًا عند تسليم اي نموذج.",
                        },
                    ]
                },
                {
                    title: language === "en" ? "Updates" : "تحديثات",
                    icon: <MdTipsAndUpdates size={40} />,
                    content: [
                        {
                            title: language === "en" ? "Form Indexing" : "ترتيب الأسئلة في النموذج",
                            description: language === "en" 
                                ? "You can now manage form questions, indexing them and sorting them as you like."
                                : "يمكنك الآن إدارة أسئلة النموذج وترتيبها كما تريد.",
                        },
                    ]
                },
                {
                    title: language === "en" ? "Improvements" : "تحسينات",
                    icon: <MdOutlineSelfImprovement size={40} />,
                    content: [
                        {
                            title: language === "en" ? "UI Enhancements" : "تحسينات في واجهة المستخدم",
                            description: language === "en" 
                                ? "Various improvements have been made to the UI to make it more user-friendly."
                                : "تم إجراء العديد من التحسينات على واجهة المستخدم لتسهيل الاستخدام.",
                        },
                        {
                            title: language === "en" ? "System Optimization" : "تحسين النظام",
                            description: language === "en" 
                                ? "Optimized the system to improve performance and make it faster."
                                : "تم تحسين النظام لزيادة الأداء وجعله أسرع.",
                        },
                    ]
                },
                {
                    title: language === "en" ? "Bug Fixes" : "إصلاحات الأخطاء",
                    icon: <FcSupport size={40} />,
                    content: [
                        {
                            title: language === "en" ? "Project bugs fixed" : "تم إصلاح أخطاء المشروع",
                            description: language === "en" 
                                ? "Fixed a bug that caused the project to crash when a user tried to create a new project."
                                : "تم إصلاح خطأ كان يتسبب في تعطل المشروع عند محاولة المستخدم إنشاء مشروع جديد.",
                        },
                        {
                            title: language === "en" ? "Project type bugs fixed" : "تم إصلاح أخطاء نوع المشروع",
                            description: language === "en" 
                                ? "Fixed a bug that caused the project type to not be updated correctly."
                                : "تم إصلاح خطأ كان يتسبب في عدم تحديث نوع المشروع بشكل صحيح.",
                        },
                        {
                            title: language === "en" ? "User bugs fixed" : "تم إصلاح أخطاء المستخدم",
                            description: language === "en" 
                                ? "Fixed a bug that caused the user to be unable to log in sometimes."
                                : "تم إصلاح خطأ كان يتسبب في عدم قدرة المستخدم على تسجيل الدخول في بعض الأحيان.",
                        },
        
                    ]
                },
            ]
        },
        {
            title: language === "en" ? "What's new in Lazem GO?" : "ما الجديد في لازِم GO؟",
            version: "1.2",
            release_date: "2024-10-16",
            update_details: [
                {
                    title: language === "en" ? "New Features" : "ميزات جديدة",
                    icon: <MdOutlineFiberNew size={40} />,
                    content: [
                        {
                            title: language === "en" ? "Employee Card System" : "نظام بطاقة الموظف",
                            description: language === "en" 
                                ? "HR department can now see all employee information in one place, and create new employee cards."
                                : "يمكن لقسم الموارد البشرية الآن رؤية جميع معلومات الموظف في مكان واحد، وإنشاء بطاقات موظف جديدة.",
                        }
                    ]
                },
                {
                    title: language === "en" ? "Updates" : "تحديثات",
                    icon: <MdTipsAndUpdates size={40} />,
                    content: [
                        {
                            title: language === "en" ? "Tasks by position" : "المهام حسب المنصب",
                            description: language === "en" 
                                ? "You can now assign tasks to employees based on their position."
                                : "يمكنك الآن تعيين المهام للموظفين استنادًا إلى منصبهم.",
                        },
                    ]
                },
                {
                    title: language === "en" ? "Enhancements" : "تحسينات",
                    icon: <MdOutlineSelfImprovement size={40} />,
                    content: [
                        {
                            title: language === "en" ? "UI Enhancements" : "تحسينات في واجهة المستخدم",
                            description: language === "en" 
                                ? "Various improvements have been made to the UI to make it more user-friendly."
                                : "تم إجراء العديد من التحسينات على واجهة المستخدم لتسهيل الاستخدام.",
                        },
                        {
                            title: language === "en" ? "System Optimization" : "تحسين النظام",
                            description: language === "en" 
                                ? "Optimized the system to improve performance and make it faster."
                                : "تم تحسين النظام لزيادة الأداء وجعله أسرع.",
                        },
                    ]
                }
            ]
        }
    ]);
    

    return (
        <div
            dir={language === "en" ? "ltr" : "rtl"}
        >
            {only_last_update ?            
                <>
                    <CardHeader>
                        <CardTitle>{updateNews[updateNews.length-1].title}</CardTitle>
                        <CardTitle>{updateNews[updateNews.length-1].version}</CardTitle>

                    </CardHeader>
                    <CardContent>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {updateNews[updateNews.length-1].update_details.map((detail: any) => (
                                <Card key={detail.title}>
                                    <CardHeader>
                                        <CardTitle
                                            className="text-center flex flex-col items-center"
                                        >
                                            <div
                                                className="mb-4 "
                                            >
                                                {detail.icon}
                                            </div>
                                            {detail.title}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardContent className="text-start">
                                        <div className="mb-4">
                                            {detail.content.map((content: any) => (
                                                <ul>
                                                    <li
                                                        className="!list-disc mb-4"
                                                    >
                                                        <p
                                                            className="font-bold !list-disc"
                                                        >
                                                            {content.title}
                                                        </p>
                                                        <p>{content.description}</p>
                                                    </li>
                                                </ul>
                                            ))}
                                        </div>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </CardContent>
                </>
                :
                <>
                    {updateNews.map((update: any) => (
                        <Card key={update.version}>
                            <CardHeader>
                                <CardTitle>{update.title}</CardTitle>
                                <CardTitle>{update.version}</CardTitle>
                            </CardHeader>
                            <CardContent>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {update.update_details.map((detail: any) => (
                                        <Card key={detail.title}>
                                            <CardHeader>
                                                <CardTitle
                                                    className="text-center flex flex-col items-center"
                                                >
                                                    <div
                                                        className="mb-4 "
                                                    >
                                                        {detail.icon}
                                                    </div>
                                                    {detail.title}
                                                </CardTitle>
                                            </CardHeader>
                                            <CardContent className="text-start">
                                                <div className="mb-4">
                                                    {detail.content.map((content: any) => (
                                                        <ul>
                                                            <li
                                                                className="!list-disc mb-4"
                                                            >
                                                                <p
                                                                    className="font-bold !list-disc"
                                                                >
                                                                    {content.title}
                                                                </p>
                                                                <p>{content.description}</p>
                                                            </li>
                                                        </ul>
                                                    ))}
                                                </div>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </>
            }
        </div>
    );
}

export default WhatIsNew;