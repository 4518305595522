import ApiUrl from "./api-url";
import axios, { isCancel } from 'axios';
import { ApiHeaderOptions } from "./api-header-options";
import { errorHandlingService } from "./error-handling-service";


const Logs = {
    list: async (filter?: any) => {
        let url = `${ApiUrl}/v1/logs/api_logs/`;
        if (filter) {
            url = `${ApiUrl}/v1/logs/api_logs/?${filter}`;
        }
        return axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            errorHandlingService(error, " list logs", "high");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/logs/logs/${id}/`;
        return axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            errorHandlingService(error, " get log", "high");
        });
    },
    getAnalytics: async () => {
        const url = `${ApiUrl}/v1/logs/analytics/`;
        return axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            errorHandlingService(error, " get analytics", "high");
        });
    }
}

const EmailLogs ={
    list: async (page_url?: any) => {
        let url = `${ApiUrl}/v1/logs/email_logs/`;
        if (page_url?.includes("page=")) {
            let page = page_url.split("=")[1];
            url = `${ApiUrl}/v1/logs/email_logs/?page=${page}`;
        }
        else if (page_url?.includes("email_logs")) {

        }
        else if (page_url) {
            url = `${ApiUrl}/v1/logs/email_logs/?page=${page_url}`;
        }
        return axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            errorHandlingService(error, " list email logs", "high");
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/logs/email_logs/${id}/`;
        return axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            errorHandlingService(error, " get email log", "high");
        });
    },
}

export { Logs, EmailLogs };