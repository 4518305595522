import ApiUrl from "./api-url";
import axios, {isCancel} from 'axios';
import {ApiHeaderOptions} from "./api-header-options";
import { errorHandlingService } from "./error-handling-service";

const tickets = {
    list: async (filter?:any) => {
        let url = `${ApiUrl}/v1/tickets/tickets/`;
        if (filter){
            url = `${ApiUrl}/v1/tickets/tickets/?${filter}`;
        }
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " get tickets", "high");
        });
    },
    list_me: async (filter?:any) => {
        let url = `${ApiUrl}/v1/tickets/tickets/me/`;
        if (filter){
            url = `${ApiUrl}/v1/tickets/tickets/me/?${filter}`;
        }
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " get my tickets", "high");
        });
    },
    get: async (id:any) => {
        const url = `${ApiUrl}/v1/tickets/tickets/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " get ticket", "high");
        });
    },
    create : async (data:any) => {
        const url = `${ApiUrl}/v1/tickets/tickets/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " create ticket", "high");
        });
    },
    options: async () => {
        const url = `${ApiUrl}/v1/tickets/tickets/`;
        return await axios.options(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " get ticket options", "high");
        });
    },
    update : async (id:any, data:any) => {
        const url = `${ApiUrl}/v1/tickets/tickets/${id}/`;
        return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " update ticket", "medium");
        });
    },
    get_my_ticket : async (id?:any) => {
        let url = `${ApiUrl}/v1/tickets/tickets/me/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " get my ticket", "high");
        });
    }
}

const categories = {
    list: async () => {
        const url = `${ApiUrl}/v1/tickets/categories/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " list categories", "high");
        });
    },
    get: async (id:any) => {
        const url = `${ApiUrl}/v1/tickets/categories/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " get category", "high");
        });
    },
    update : async (id:any, data:any) => {
        const url = `${ApiUrl}/v1/tickets/categories/${id}/`;
        return await axios.put(url, data, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " update category", "medium");
        });
    },
    create : async (data:any) => {
        const url = `${ApiUrl}/v1/tickets/categories/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " create category", "medium");
        });
    },
    delete : async (id:any) => {
        const url = `${ApiUrl}/v1/tickets/categories/${id}/`;
        return await axios.delete(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " delete category", "medium");
        });
    },
    getWithFilter: async (filter:any) => {
        const url = `${ApiUrl}/v1/tickets/categories/?${filter}`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " get category with filter", "high");
        });
    },
    options: async () => {
        const url = `${ApiUrl}/v1/tickets/categories/`;
        return await axios.options(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " get category options", "high");
        });
    }
}

const comments = {
    list: async () => {
        const url = `${ApiUrl}/v1/tickets/comments/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " list comments", "high");
        });
    },
    create : async (data:any) => {
        const url = `${ApiUrl}/v1/tickets/comments/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            errorHandlingService(error, " create comment", "medium");
        });
    },
}


export {tickets, categories, comments};