import { useLanguage } from "@/components/context/LanguageContext";
import LoadingIcon from "@/components/loading/loading-icon";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardTitle, CardDescription, CardFooter } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { account } from "@/services/user-service";
import { Label } from "@radix-ui/react-label";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import { is_english, is_arabic } from "@/services/language-checker";

const Register = () => {
    const { language, changeLanguage } = useLanguage();
    const [userInfo, setUserInfo] = useState({
        firstName: '',
        lastName: '',
        middle_name: '',
        email: '',
        password: '',
        first_name_ar: '',
        last_name_ar: '',
        middle_name_ar: '',
    });

    useEffect(() => {
        document.title = 'Lazem GO - Register';
    } , []);

    const [error, setError] = useState('');
    const navigate  = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleChange = (e:any) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };
    const handleRegister = async () => {
        if (checkFields()) {
            let data = {
                "email": userInfo.email.toLowerCase(),
                "username": userInfo.email.toLowerCase(),
                "password": userInfo.password,
                "first_name": userInfo.firstName,
                "last_name": userInfo.lastName,
                "middle_name": userInfo.middle_name,
                "first_name_ar": userInfo.first_name_ar,
                "last_name_ar": userInfo.last_name_ar,
                "middle_name_ar": userInfo.middle_name_ar,
            }
            setLoading(true);
            await account.register(data).then(() => {
                setIsSuccess(true);
            }).catch((error) => {
                if (error.response) {
                    let messages:any = []
                    Object.keys(error.response.data).forEach((key) => {
                        messages.push(error.response.data[key][0]);
                      });
                    for (let i = 0; i < messages.length; i++) {
                        if (messages[i] === 'user with this email already exists.') {
                            setError(language === 'en' ? 'Email already exists' : 'البريد الإلكتروني موجود بالفعل');
                        } else {
                            setError(messages.join(', '));
                        }
                    }
                }
                else {
                    setError(error.message);
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    const checkEmail = () => {
        if (!userInfo.email.endsWith('@lazem.sa')) {
          setError(language === 'en' ? 'Please use Lazem email' : 'يرجى استخدام بريد Lazem');
          return false;
        }
        return true;
      }

    const checkFields = () => {
        if (!userInfo.firstName || !userInfo.lastName || !userInfo.email || !userInfo.password || !userInfo.first_name_ar || !userInfo.last_name_ar) {
            setError(language === 'en' ? 'Please fill all fields' : 'يرجى ملء جميع الحقول');
            return false;
        }
        else if (userInfo.firstName && !is_english(userInfo.firstName)) {
            setError(language === 'en' ? 'English fields should be in English' : 'يجب أن تكون الحقول الإنجليزية باللغة الإنجليزية');
            return false;
        }
        else if (userInfo.lastName && !is_english(userInfo.lastName)) {
            setError(language === 'en' ? 'English fields should be in English' : 'يجب أن تكون الحقول الإنجليزية باللغة الإنجليزية');
            return false;
        }
        else if (userInfo.first_name_ar && !is_arabic(userInfo.first_name_ar)) {
            setError(language === 'en' ? 'Arabic fields should be in Arabic' : 'يجب أن تكون الحقول العربية باللغة العربية');
            return false;
        }
        else if (userInfo.last_name_ar && !is_arabic(userInfo.last_name_ar)) {
            setError(language === 'en' ? 'Arabic fields should be in Arabic' : 'يجب أن تكون الحقول العربية باللغة العربية');
            return false;
        }
        else if (!isPasswordValid) {
            setError(language === 'en' ? 'Password should be at least 8 characters, contain at least one special character, one number and one capital letter' : 'يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل وتحتوي على حرف خاص ورقم وحرف كبير واحد على الأقل');
            return false;
        }
        else if (!checkEmail()) {
            setError(language === 'en' ? 'Please use Lazem email' : 'يرجى استخدام بريد Lazem');
            return false;
        }
        setError('');
        return true;
    };


    return (
        <Card
            className="w-full md:w-4/5 lg:w-3/5 xl:w-2/5 mx-auto my-8"
            dir={language === 'en' ? 'ltr' : 'rtl'}
        >
            <CardContent
                className="flex flex-col text-start "
            >
                    <div
                        className="flex justify-start mt-4"
                    >
                        <Button
                            onClick={() => changeLanguage(language === 'en' ? 'ar' : 'en')}
                            variant={"outline"}
                        >
                            {language === 'en' ? 'العربية' : 'English'}
                        </Button>
                    </div>
                <CardTitle
                    className="text-2xl font-bold text-center"
                >
                    {language === 'en' ? 'Register' : 'تسجيل'}
                </CardTitle>
                {isSuccess ?
                    <CardDescription
                        className="text-green-500 font-bold text-center my-4"
                    >
                        <p
                            className="text-green-500 font-bold text-center my-4"
                        >
                            {language === 'en' ? 'Registration successful' : 'تم التسجيل بنجاح'}
                        </p>

                        <p>
                         {language === 'en' ? 'Please check your email to activate your account' : 'يرجى التحقق من بريدك الإلكتروني لتفعيل حسابك'}
                        </p>
                    </CardDescription>
                    : 
                    <>                    
                        <div
                            className="my-4"
                        >
                            <Label>
                                {language === 'en' ? 'First Name (English)' : 'الاسم الأول (الإنجليزية)'} <span className="text-red-500">*</span>
                            </Label>
                            <Input type="text" name="firstName" value={userInfo.firstName} onChange={handleChange} />
                        </div>
                        <div
                            className="my-4"
                        >
                            <Label>
                                {language === 'en' ? 'Middle Name (English)' : 'الاسم الأوسط (الإنجليزية)'}
                            </Label>
                            <Input type="text" name="middle_name" value={userInfo.middle_name} onChange={handleChange} />
                        </div>
                        <div
                            className="my-4"
                        >
                            <Label>
                                {language === 'en' ? 'Last Name (English)' : 'الاسم الأخير (الإنجليزية)'} <span className="text-red-500">*</span>
                            </Label>
                            <Input type="text" name="lastName" value={userInfo.lastName} onChange={handleChange} />
                        </div>
                        <div
                            className="my-4"
                        >
                            <Label>
                                {language === 'en' ? 'First Name (Arabic)' : 'الاسم الأول (العربية)'} <span className="text-red-500">*</span>
                            </Label>
                            <Input type="text" name="first_name_ar" value={userInfo.first_name_ar} onChange={handleChange} />
                        </div>
                        <div
                            className="my-4"
                        >
                            <Label>
                                {language === 'en' ? 'Middle Name (Arabic)' : 'الاسم الأوسط (العربية)'}
                            </Label>
                            <Input type="text" name="middle_name_ar" value={userInfo.middle_name_ar} onChange={handleChange} />
                        </div>
                        <div
                            className="my-4"
                        >
                            <Label>
                                {language === 'en' ? 'Last Name (Arabic)' : 'الاسم الأخير (العربية)'} <span className="text-red-500">*</span>
                            </Label>
                            <Input type="text" name="last_name_ar" value={userInfo.last_name_ar} onChange={handleChange} />
                        </div>
                        <div
                            className="my-4"
                        >
                            <Label>
                                {language === 'en' ? 'Your Lazem Email' : 'بريدك الإلكتروني الخاص بلازم'} <span className="text-red-500">*</span>
                            </Label>
                            <Input type="email" name="email" value={userInfo.email} onChange={handleChange} />
                        </div>
                        <div
                            className="my-4"
                        >
                            <Label>
                                {language === 'en' ? 'Password' : 'كلمة المرور'} <span className="text-red-500">*</span>
                            </Label>
                            <Input type="password" name="password" value={userInfo.password} onChange={handleChange} />
                            <PasswordChecklist
                                    rules={["minLength","specialChar","number","capital"]}
                                    value={userInfo.password}
                                    minLength={8}
                                    iconSize={12}
                                    validColor="green"
                                    invalidColor="red"
                                    validTextColor="green"
                                    invalidTextColor="red"
                                    rtl={language === 'ar'}
                                    messages={{
                                        minLength: language === 'en' ? 'Minimum 8 characters' : 'الحد الأدنى 8 أحرف',
                                        specialChar: language === 'en' ? 'At least 1 special character' : 'على الأقل 1 حرف خاص',
                                        number: language === 'en' ? 'At least 1 number' : 'على الأقل 1 رقم',
                                        capital: language === 'en' ? 'At least 1 capital letter' : 'على الأقل 1 حرف كبير',
                                    }}
                                    onChange={(isValid: boolean) => {
                                        setIsPasswordValid(isValid);
                                        if (isValid) {
                                            setIsPasswordValid(true);
                                        } 
                                    }}
                                />
                        </div>
                    </>
                }
                <CardDescription
                    className="text-danger text-red-500 font-bold"
                >
                    {error}
                </CardDescription>
            </CardContent>
            <CardFooter
                className="flex flex-col"
            >
                {isSuccess ?
                    <div
                        className="flex justify-start"
                    >
                        <Button
                            onClick={() => navigate('/login')}
                            variant={"secondary"}
                        >
                            {language === 'en' ? 'Back to Login' : 'العودة إلى تسجيل الدخول'}
                        </Button>
                    </div>
                    :
                    <>                    
                        <Button 
                                onClick={() => handleRegister()} 
                                disabled={loading || !isPasswordValid}
                                className="m-auto"
                            >
                            {loading ? 
                                <LoadingIcon />
                                : 
                                language === 'en' ? 'Register' : 'تسجيل'
                            }
                        </Button>
                        <div
                            className="flex justify-start w-full mt-4 text-start flex-col gap-2"
                        >
                            <div>
                                <Label 
                                    className="text-sm"
                                >
                                    {language === 'en' ? 'Already have an account?' : 'لديك حساب بالفعل؟'}
                                </Label>
                                <Button
                                    onClick={() => navigate('/login')}
                                    variant={"link"}
                                    disabled={loading}
                                >
                                    {language === 'en' ? 'Login' : 'تسجيل الدخول'}
                                </Button>
                            </div>
                        </div>
                    </>
                }
                
            </CardFooter>
        </Card>
    );
}

export default Register;