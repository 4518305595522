import { useLanguage } from "./context/LanguageContext";
import { Badge } from "./ui/badge";
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "@/components/ui/collapsible"
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import { Button } from "./ui/button";
import { ChevronsUpDown } from "lucide-react";

const Section = ({ title, content }: { title: string; content: React.ReactNode }) => (
    <div className="my-6 border-t border-gray-300 pt-4">
        <h3 className="text-lg font-semibold mb-2">{title}</h3>
        <p className="">{content}</p>
    </div>
);

const OnboardingTicketInstruction = ({ onboarding_info }: any) => {
    const { language } = useLanguage();

    const findInfo = (name: string) => {
        const info = onboarding_info.find((item: any) => item?.name === name);
        return info?.answer || "";
    };

    const instructions = language === "en"
        ? "Instructions of how to onboard the new employee"
        : "تعليمات كيفية تسجيل الموظف الجديد";

    return (
        <Card>
            <CardHeader>
                <CardTitle
                    className="text-xl font-bold text-center"
                >
                    {instructions}
                </CardTitle>
            </CardHeader>
            <CardContent>
                <Section
                    title={language === "en" ? "Use the following first name when creating the account" : "استخدم الاسم الأول التالي عند إنشاء الحساب"}
                    content={`${findInfo("english_first_name")}`}
                />

                <Section
                    title={language === "en" ? "Use the following last name when creating the account" : "استخدم الاسم الأخير التالي عند إنشاء الحساب"}
                    content={`${findInfo("english_last_name")}`}
                />

                <Section
                    title={language === "en" ? "Use the following email when creating the Outlook account" : "استخدم البريد الإلكتروني التالي عند إنشاء حساب Outlook"}
                    content={`${findInfo("english_first_name")?.toLowerCase()}.${findInfo("english_last_name")?.toLowerCase()}@lazem.sa`}
                />
                {findInfo("it_services") && (
                    <Section
                        title={language === "en" ? "Provide the following IT services to the new employee" : "قدم الخدمات التقنية التالية للموظف الجديد"}
                        content={
                            <div className="flex flex-wrap gap-2">
                                {findInfo("it_services").map((service: string) => (
                                    <Badge key={service}>
                                        {service.split("_").join(" ")}
                                    </Badge>
                                ))}
                            </div>
                        }
                    />
                )}
                <Section
                    title={language === "en" ? "Add the new employee to the following department" : "أضف الموظف الجديد إلى القسم التالي"}
                    content={findInfo("department")}
                />

                <Section
                    title={language === "en" ? "Add the new employee to the following job title" : "أضف الموظف الجديد إلى الوظيفة التالية"}
                    content={findInfo("role")}
                />

                <Section
                    title={language === "en" ? "Use the following phone number when creating the account" : "استخدم رقم الجوال التالي عند إنشاء الحساب"}
                    content={findInfo("phone")}
                />

                {findInfo("it_note") && (
                    <Section
                        title={language === "en" ? "Take in consideration the following IT note" : "خذ في الاعتبار الملاحظة التقنية التالية"}
                        content={findInfo("it_note")}
                    />
                )}

                {findInfo("general_note") && (
                    <Section
                        title={language === "en" ? "Take in consideration the following general note" : "خذ في الاعتبار الملاحظة العامة التالية"}
                        content={findInfo("general_note")}
                    />
                )}
                <Collapsible>
                    <div 
                        className="flex items-center cursor-pointer"
                    >
                        <CollapsibleTrigger asChild>
                            <Button variant="ghost" size="sm" className="ml-2">
                                <ChevronsUpDown className="h-4 w-4" />
                                <span className="sr-only">Toggle</span>
                            </Button>
                        </CollapsibleTrigger>
                        <h4 className="text-sm font-semibold">
                            {language === "en" ? "view more" : "عرض المزيد"}
                        </h4>
                    </div>
                    <CollapsibleContent>
                        {onboarding_info?.map((question: any, index: any) => (
                            <Section
                                key={index}
                                title={language === "en" ? question?.question_en : question?.question_ar}
                                content={question?.answer}
                            />
                        ))}
                    </CollapsibleContent>
                </Collapsible>
            </CardContent>
        </Card>
    );
};

export default OnboardingTicketInstruction;
