import { useLanguage } from "./context/LanguageContext";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardDescription,
    CardFooter
} from "@/components/ui/card";
import { Button } from "./ui/button";
import { useState } from "react";

const OnboardingTicketInstructionForTheUser = () => {
    const { language } = useLanguage();
    const [copied, setCopied] = useState(false);
    const Section = ({ title, content }: { title: string; content?: any }) => (
        <div className="my-4 pt-4">
            <h3 className="text-lg font-semibold mb-2">{title}</h3>
            {content}
        </div>
    );
    const copyToClipboard = () => {
        const sections = document.querySelectorAll("#copy > div");
        let copyText = "";

        sections.forEach((section) => {
            const title = section.querySelector("h3")?.textContent || "";
            const contentElement = section.querySelector("p") || section.querySelector("ul");

            let content = "";

            if (contentElement?.tagName === "UL") {
                // Handle list content
                const listItems = contentElement.querySelectorAll("li");
                content = Array.from(listItems)
                    .map((li) => `- ${li.textContent?.trim()}`)
                    .join("\n");
            } else {
                // Handle plain text content
                content = contentElement?.textContent?.trim() || "";
            }

            copyText += `${title}\n${content}\n\n`;
        });

        navigator.clipboard.writeText(copyText.trim());
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };

    return (
        <div>
            <Card>
                <CardHeader>
                    <CardTitle
                        className="text-xl font-bold text-center"
                    >
                        {language === "en"
                            ? "Important instructions for the new employee"
                            : "تعليمات مهمة للموظف الجديد"}
                    </CardTitle>
                    <CardDescription
                        className="text-center"
                    >
                        {language === "en"
                            ? "Please copy the following information and send it to the new employee after the onboarding process."
                            : "يرجى نسخ المعلومات التالية وإرسالها إلى الموظف الجديد. بعد انتهاء عملية التسجيل."}
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <div
                        id="copy"
                    >
                        <Section
                            title={language === "en" ? "Go to the following link to sign in to your account" : "انتقل إلى الرابط التالي لتسجيل الدخول إلى حسابك"}
                            content={<p>"https://www.office.com/"</p>}
                        />
                        <Section
                            title={language === "en" ? "Use the given email and password to sign in" : "استخدم البريد الإلكتروني وكلمة المرور المعطاة لتسجيل الدخول"}
                        />
                        <Section
                            title={language === "en" ? "Complete the microsoft instructions to complete your account setup" : "أكمل تعليمات مايكروسوفت لإكمال إعداد حسابك"}
                        />
                        <Section
                            title={language === "en" ? "After completing the setup, download the following apps for the internal communication" : "بعد الانتهاء من الإعداد، قم بتنزيل التطبيقات التالية للتواصل الداخلي"}
                            content={
                                <ul className="list-disc pl-4">
                                    <li>Microsoft Teams</li>
                                    <li>Microsoft Outlock</li>
                                </ul>
                            }
                        />
                    </div>
                </CardContent>
                <CardFooter
                    className="flex flex-col"
                >
                    {copied && (
                        <p className="text-green-500 text-center">
                            {language === "en" ? "Copied to clipboard" : "تم النسخ إلى الحافظة"}
                        </p>
                    )}
                    <Button
                        className="w-full"
                        onClick={copyToClipboard}
                    >
                        {language === "en" ? "Copy" : "نسخ"}
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
}

export default OnboardingTicketInstructionForTheUser;