import { useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import LoadingIcon from "@/components/loading/loading-icon";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "@/components/context/LanguageContext";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardDescription,
} from "@/components/ui/card";
import { tickets } from "@/services/tickets-services";

const AddTrainingCertificate = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [certificateForm, setCertificateForm] = useState<any>({
        title: "Add Training Certificate",
        service_type: "add_training_certificate",
        description: "A new certificate needs to be added to the system.",
        priority: "High",
        json_request: [
            {
                question_en: "Company Name",
                question_ar: "اسم الشركة",
                answer: "",
                is_required: true,
                name: "company_name",
            },
            {
                question_en: "General Note",
                question_ar: "ملاحظة عامة",
                answer: "",
                is_required: false,
                name: "general_note",
            }
        ],
        category: null,
    });
    const [formErrors, setFormErrors] = useState<any>("");

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        let updatedForm = certificateForm.json_request.map((item: any) => {
            if (item.name === name) {
                return { ...item, answer: value };
            }
            return item;
        });
        setCertificateForm({ ...certificateForm, json_request: updatedForm });
    };

    const checkRequiredFields = () => {
        let requiredFields = certificateForm.json_request.filter(
            (item: any) => item.is_required && !item.answer
        );
        if (requiredFields.length > 0) {
            setFormErrors(
                language === "en"
                    ? "Please fill out all required fields."
                    : "يرجى ملء جميع الحقول المطلوبة."
            );
            return false;
        }
        return true;
    };

    const handleSubmit = async (e: any) => {
        if (!checkRequiredFields()) {
            return;
        }
        setFormErrors("");
        e.preventDefault();
        setLoading(true);
        tickets.create(certificateForm).then((response) => {
            navigate("/my-requests/id/" + response?.data?.id);
        }).catch((error: any) => {
            console.log('Form Error', error);
            throw error;
        }).finally(() => {
            setLoading(false);
        });
    };

    const setInputFieldDev = (
        name: string,
        placeholder: string,
        is_required: boolean
    ) => {
        const field = certificateForm.json_request.find(
            (item: any) => item.name === name
        );
        const value = field ? field.answer : "";

        return (
            <div className="flex flex-col items-center justify-center gap-4 w-11/12 my-6 mx-auto">
                <Label className="w-full ">
                    {placeholder}{" "}
                    {is_required && <span className="text-red-500"> *</span>}
                </Label>
                <Input
                    className="mt-[-8px]"
                    name={name}
                    value={value}
                    onChange={handleChange}
                />
            </div>
        );
    };

    return (
        <div
            className="flex flex-col items-center justify-center"
            dir={language === "en" ? "ltr" : "rtl"}
        >
            {
                <Card className="flex flex-col items-center justify-center gap-4 w-full my-4 p-4 lg:w-1/2 mx-auto">
                    <CardHeader
                        className="w-full"
                    >
                        <Button
                            onClick={() => navigate("/special-request")}
                            className="w-full my-4"
                            variant={"secondary"}
                        >
                            {language === "en" ? "Back" : "عودة"}
                        </Button>
                        <CardTitle>
                            {language === "en" ? "Add Training Certificate" : "إضافة شهادة تدريبية"}
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="text-start w-full">
                        <CardDescription className="text-center">
                            {language === "en"
                                ? "Please fill out the form below to complete the onboarding process."
                                : "يرجى ملء النموذج أدناه لإكمال عملية البدء في العمل."}

                            {formErrors && (
                                <div className="text-red-500 my-4">{formErrors}</div>
                            )}
                        </CardDescription>

                        {
                            setInputFieldDev('company_name', language === "en" ? "Company Name" : "اسم الشركة", true)
                        }
                        <div className="flex flex-col items-center justify-center gap-4 w-11/12 my-4 mx-auto">
                            <Label className="w-full">
                                {language === "en" ? "General Note" : "ملاحظة عامة"}
                            </Label>
                            <Textarea
                                name="general_note"
                                value={certificateForm.json_request.general_note}
                                placeholder={language === "en" ? "General Note" : "ملاحظة عامة"}
                                onChange={handleChange}
                            />
                        </div>
                    </CardContent>

                    <Button onClick={handleSubmit} disabled={loading}>
                        {loading ? <LoadingIcon /> : language === "en" ? "Submit" : "إرسال"}
                    </Button>
                </Card>
            }
        </div>
    );
};

export default AddTrainingCertificate;
